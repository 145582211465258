.button {
  cursor: pointer;
  font-size: var(--font-sm);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-weight: normal;
  padding: 1.1rem 1.6rem;
  border: none;

  &.primary {
    background: var(--primary-btn-bg);
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.65rem 1.6rem;
    line-height: 1;

    &:not([disabled]):hover {
      background: var(--primary-btn-hover);
    }

    &:not([disabled]):active {
      background-color: var(--primary-btn-active);
    }
  }

  &.primary-action {
    background: linear-gradient(90deg, #2d42fc, #2e3dcd);
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.65rem 1.6rem;
    line-height: 1;

    &:not([disabled]):hover {
      background: var(--primary-btn-hover);
      box-shadow: 0 0 0.4rem 0.5rem rgb(45 66 252 / 20%);
    }

    &:not([disabled]):active {
      background-color: var(--primary-btn-active);
      box-shadow: 0 0 0.4rem 0.6rem rgb(45 66 252 / 20%);
    }
  }

  &.semi-clear {
    background: rgb(43, 55, 94);
    color: #fff;
    border-radius: var(--border-radius-sm);
    line-height: 1;
    letter-spacing: 0;

    &:not([disabled]):hover {
      background: rgb(54, 69, 117);
    }

    &:not([disabled]):active {
      background: rgb(64, 82, 141);
    }
  }

  &.clear {
    background-color: transparent;
    border: 1px solid var(--dark-blue-border);
    border-radius: var(--border-radius-sm);
    color: #fff;
    padding: 0.75rem 1.25rem;
    font-weight: normal;
    justify-content: left;

    > .btn-image {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      max-height: 2.3rem;
      max-width: 2.3rem;
      margin-right: 1.15rem;
    }

    &:not([disabled]):hover {
      background: var(--dark-blue-hover);
    }

    &:not([disabled]):active {
      background: var(--dark-blue-active);
    }
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
    opacity: 0.9;
  }
}
